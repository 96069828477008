@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url(../src/assets/css/gilory.css);

:root {
  --primary-color: #6C7692;
  --secondary-color: #E6218C;
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-blue: #0077FF;
  --color-lightbg: #FFEBE4;
  --family1: "Inter", sans-serif;
  --family2: "Gilmer";
  --linenormal: normal;
  --bordercolor: #D0D0D0;
  --iconActive: #FF0000;
  --active: #07C325;
  --error: #FF0000;
  --btnBorder: #aaa;
  --btnSecColor: #5A5A5A;
  --fs8: 8px;
  --fs10: 10px;
  --fs12: 12px;
  --fs14: 14px;
  --fs16: 16px;
  --fs18: 18px;
  --fs20: 20px;
  --fs22: 22px;
  --fs24: 24px;
  --fs26: 26px;
  --fs28: 28px;
  --fs32: 32px;
  --fs34: 34px;
  --fs36: 36px;
  --fs38: 38px;
  --fs40: 40px;
  --fs42: 42px;
  --fs44: 44px;
  --fs46: 46px;
  --fs48: 48px;
  --fw300: 300;
  --fw400: 400;
  --fw500: 500;
  --fw600: 600;
  --fw700: 700;
  --fw800: 800;
  --fw900: 900;
  --radius8: 8px;
  --radius10: 10px;
  --radius12: 12px;
  --radius14: 14px;
  --radius16: 16px;
  --green: #22c55e;
  --red: #ef3f3f;
  --pending: #ffd90f;
  --draft: #9f9c9c;
}

.fs14 {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--family2) !important;
  letter-spacing: 0px !important;
}

p {
  font-style: var(--fs16);
  letter-spacing: 0px !important;

}

label,
p,
body {
  font-family: var(--family1) !important;
  letter-spacing: 0px !important;
}

td a,
th a {
  color: var(--color-blue) !important;
  font-size: 14px !important;
}

.mt-20 {
  margin-top: 20px;
}

.wrap {
  height: auto;
  overflow: hidden;
  width: 100%;
}

.radius10 {
  border-radius: 10px;
}

/*  Header */

.closeSidebar {
  display: none;
  position: absolute;
  top: 18px;
  right: 10px;
  cursor: pointer;
  color: var(--primary-color);
}

.closeSidebar:hover {
  color: var(--color-blue);
}


/* Page Title */

.pageTitle {
  min-height: var(--fs42);
  margin-bottom: var(--fs24);
}

.bodyArea {}






.mainheader {
  /* padding-left: 225px; */
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 255px);
  background: var(--color-white);
  z-index: 9 !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out !important;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05) !important;

}

.activeHeader.mainheader {
  /* padding-left: 65px; */
  width: calc(100% - 80px);
  transition: all 0.1s ease-in-out !important;
}

.avtarImg {
  border: 1px solid #e7e7e7;
  border-radius: 50px;
  overflow: hidden;
  padding: 2px;
}

.MuiTooltip-tooltipArrow {
  background: var(--color-blue) !important;
  font-family: var(--family1) !important;
  font-weight: 400 !important;
  letter-spacing: 0.2px;
  padding: 10px 12px !important;
}

.MuiTooltip-tooltipArrow .MuiTooltip-arrow {
  color: var(--color-blue) !important;
}

.subsTooltip .MuiTooltip-tooltipArrow {
  background: transparent !important;
  padding: 0px !important;
  letter-spacing: 0px !important;
}




.paymentDue {
  color: var(--color-black);
  font-size: var(--fs13);
  font-weight: var(--fw600);
}

.green {
  color: var(--green);
}

.red {
  color: var(--red);
}

.packageDetails p svg.green path {
  fill: var(--green);
}

.packageDetails p svg.red path {
  fill: var(--red);
}



/* .MuiTooltip-tooltipArrow .MuiTooltip-arrow{
  color: var(--color-blue) !important;
}

.MuiTooltip-tooltipArrow svg[data-testid="InfoIcon"] path {
  fill:var(--color-blue);
}

.blueColor{
  width: 18px;
}

.blueColor path{
  fill: var(--color-blue);
} */



/* Table Scrollbar */

.sidebar::-webkit-scrollbar {
  width: 0px;
}

.sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}


/* Table CSS */

.MuiTableContainer-root::-webkit-scrollbar {
  width: 0px;
}

.MuiTableContainer-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.paginationSec {
  border-top: 1px solid rgba(170, 170, 170, 0.3);
}

.rowPerPage,
.pageSteps {
  font-family: var(--family1);
}

.rowPerPage>div.MuiBox-root {
  min-width: auto;
}

.rowPerPage .MuiFormControl-root .MuiInputBase-root fieldset {
  border: 0px;
}

.rowPerPage div.MuiSelect-select {
  line-height: normal;
  display: flex;
  align-items: center;
  position: relative;
  top: 4px;
  color: var(--color-black);
  font-weight: 500;
}

.rowPerPage .MuiInputBase-root .MuiSvgIcon-root {
  font-size: 18px;
  top: 5px;
  right: 12px;
}

.rowPerPage .MuiInputBase-root .MuiSvgIcon-root,
.rowPerPage .MuiInputBase-root .MuiSvgIcon-root path {
  fill: var(--color-black) !important;
}

.thumbnailImg img {
  height: auto;
  width: 100%;
}

.thumbnailimg2 {
  text-align: right;
}

.thumbnailimg2 img {
  height: 350px;
  width: auto;
}

.logoThumb {
  width: 110px;
  height: 110px;
  border-radius: 10px;
  border: 1px solid rgba(170, 170, 170, 0.3);
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoThumb img {
  max-width: 100%;
  height: auto;
}


.pagination-cover ul.MuiPagination-ul li button {
  width: auto;
  height: 30px;
  min-width: auto;
  margin: 0px 2px;
  color: #aaa;
  border-radius: 4px;
  padding: 0px 10px;
}

.pagination-cover ul.MuiPagination-ul li button.Mui-selected {
  background: var(--color-blue);
  color: var(--color-white);
  font-weight: 600;
  font-size: 12px;
  font-family: var(--family1);
  border: 1px solid var(--color-blue);
}



th,
td {
  vertical-align: middle;
}



table thead tr th {
  height: auto;
  padding: 16px 10px !important;
  font-weight: var(--fw600) !important;
  background: #F0F7FF !important;
  color: var(--color-black) !important;
  font-family: var(--family1) !important;
  font-size: 14px !important;
  border-bottom: 1px solid rgba(170, 170, 170, 0.3) !important;
  line-height: normal !important;
}


table thead tr th:first-child {
  padding-left: 16px !important;
}

table thead tr th:last-child {
  padding-right: 16px !important;
}

table thead tr th span:hover {
  opacity: 1 !important;
  color: rgba(0, 0, 0, 1) !important;
}

table tbody tr td,
table tbody tr th {
  height: auto;
  padding: 10px 10px !important;
  font-weight: 400 !important;
  background: #fff !important;
  color: var(--primary-color) !important;
  font-family: var(--family1) !important;
  font-size: 14px !important;
  border-bottom: 1px solid rgba(170, 170, 170, 0.3) !important;
  letter-spacing: 0px !important;
}

table tbody tr td:first-child {
  padding-left: 16px !important;
}

table tbody tr th:first-child {
  padding-left: 16px !important;
}

table tbody tr td:last-child {
  padding-right: 16px !important;
}

/* table tbody tr:last-child td:first-child {
    border-radius: 0px 0px 0px 10px;
  }
  table tbody tr:last-child td:last-child {
    border-radius: 0px 0px 10px 0px;
  } */

table tbody tr td .MuiAvatar-circular,
table tbody tr th .MuiAvatar-circular {
  width: 30px;
  height: 30px;

}

/* table tbody tr td, table tbody tr th
{
  border-bottom: 0px !important;
}

table tbody tr:nth-child(2n) td, table tbody tr:nth-child(2n) th{
  background: #f9f9f9 !important;
  border: 0px !important;
} */



.MuiFormLabel-asterisk,
.MuiFormControlLabel-asterisk,
.impAstric {
  color: var(--error);
  font-size: 16px;
}

.MuiFormControlLabel-label {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: var(--primary-color);
  font-family: var(--family1) !important;
  padding-left: 5px;
}

.MuiTablePagination-actions {
  gap: 5px;
  display: flex;
}

.MuiTablePagination-actions button {
  border: 1px solid #aaa;
  border-radius: 0;
  padding: 0px;
  width: 35px;
  height: 35px;
}

.MuiChip-filled {
  line-height: 24px;
  height: auto !important;
  min-height: auto;
  font-size: 12px !important;
  text-transform: capitalize;
  font-family: var(--family1) !important;
  font-weight: 400 !important;
  background: var(--color-blue) !important;
  color: var(--color-white) !important;
}

.MuiChip-filled svg path {
  fill: var(--color-white);
}



/*  Sidebar */

.sidebar {
  border: 0;


  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.sidebar .sidebarLogo {
  height: 64px;
  background-color: var(--co-primary);
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sidebarLogo .fullLogo {
  height: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}


.sidebar:hover {
  max-width: 255px;
}




.sidebar .sidebarLogo .logoIcon {

  opacity: 0;
  visibility: hidden;
}


.activeSidebar .sidebarLogo .fullLogo {
  opacity: 0;
  visibility: hidden;
  width: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.activeSidebar .sidebarLogo .logoIcon {
  opacity: 1;
  visibility: visible;
  height: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}


.sidebar li {
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 0px 0px;
}

.sidebar li a {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin: 0px;
  background: var(--color-white);
  border-radius: 5px;
  font-size: var(--fs14);
  font-weight: var(--fw500);
  color: var(--primary-color);
  font-family: var(--family1);
  padding: 0px 12px;
  height: 48px;
}

.sidebar li>div {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin: 0px;
  background: var(--color-white);
  border-radius: 5px;
  font-size: var(--fs14);
  font-weight: var(--fw500);
  color: var(--primary-color);
  font-family: var(--family1);
  padding: 0px 12px;
  height: 48px;
}



.listItemIcon {
  min-width: auto !important;
  padding: 0;
}

.listItemIcon svg {
  height: auto;
  width: 20px;
}

.sidebar::-webkit-scrollbar {
  width: 0px;
}

.sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.tabText {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--family1);
  white-space: nowrap;

}



.sidebar li a img,
.sidebar li>div img {
  width: 24px;
  height: auto;
  filter: brightness(0) saturate(100%) invert(47%) sepia(12%) saturate(784%) hue-rotate(187deg) brightness(95%) contrast(89%);
}

.sidebar li a.active img,
.sidebar li a:hover img,
.sidebar li>div:hover img,
.sidebar li>div.active img {
  filter: brightness(0) saturate(100%) invert(24%) sepia(66%) saturate(3692%) hue-rotate(205deg) brightness(109%) contrast(106%);
}

.activeSidebar.sidebar li a .tabText,
.activeSidebar.sidebar li>div .tabText {
  visibility: hidden;
  opacity: 0;
}

.activeSidebar.sidebar li>div .tabText+svg {
  display: none;
}

.activeSidebar.sidebar:hover li a div.tabText,
.activeSidebar.sidebar:hover li>div .tabText {
  visibility: visible;
  opacity: 1;
}

.activeSidebar.sidebar:hover li>div .tabText+svg {
  display: block;
}

.activeSidebar.sidebar .dropParent {
  opacity: 0px;
  visibility: hidden;
}

.activeSidebar.sidebar:hover .dropParent {
  opacity: 1px;
  visibility: visible;
}



.activeSidebar.sidebar:hover .logoIcon {
  opacity: 0px;
  visibility: hidden;
  width: 0px;
}

.activeSidebar.sidebar:hover .fullLogo {
  opacity: 1;
  visibility: visible;
  width: fit-content;
}

.dropParent {
  background: transparent;
  padding-left: 35px;
}

.dropIt {
  margin-bottom: 5px !important;
}

.dropIt>div {
  background: transparent;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  padding: 10px 12px;
  gap: 10px;
}

.bulletIcon {
  width: 6px;
  height: 6px;
  background: var(--primary-color);
  border-radius: 10px;
}

.textSubTab {
  font-size: 13px;
  font-weight: 500;
  color: var(--primary-color);
  white-space: nowrap;
}

.dropIt>div.activeItem {
  background: rgba(0, 119, 255, 0.05);
}

.dropIt>div.activeItem .bulletIcon {
  background: var(--color-blue);
}

.dropIt>div.activeItem .textSubTab {
  color: var(--color-blue);
}





/* Filter CSS */


.sContrl {
  width: 250px;
}

.sContrl .cFilter {
  width: calc(100% - 300px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.allFilter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.allFilter .MuiOutlinedInput-root {
  border-radius: 5px !important;
  height: 40px !important;
}

.allFilter .MuiOutlinedInput-root fieldset {
  border: 1px solid rgba(170, 170, 170, 0.3) !important;
}

.downloadCSV {
  border-radius: 5px !important;
  background: rgba(0, 119, 255, 0.10) !important;
  height: 40px;
  color: var(--color-blue) !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 0px !important;
}

.downloadCSV svg {
  width: 20px !important;
  height: auto;
}

.downloadCSV svg path {
  fill: var(--color-blue) !important;
}






/*  Form */

input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-black) !important;
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #fff;
}


.MuiFormHelperText-root,
p.error {
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0px !important;
  margin: 0px !important;
}

.error {
  color: var(--error);
}

/*  Buttons */

.btnPrimary {
  font-size: 16px;
  font-weight: 500;
  line-height: 38px;
  padding: 0px 16px;
  border-radius: 5px;
  color: var(--color-white);
  font-family: var(--family1);
  background: var(--color-blue);
  letter-spacing: 0.3px;
  border: 2px solid var(--color-blue);
}

.btnPrimary:hover {
  background: #006BE5;
  border-color: #006BE5;
}


.btnSecondary {
  font-size: 16px;
  font-weight: 500;
  line-height: 38px;
  padding: 0px 16px;
  border-radius: 5px;
  color: var(--btnSecColor);
  font-family: var(--family1);
  background: transparent;
  letter-spacing: 0.3px;
  border: 2px solid var(--btnSecColor);
}

.btnSecondary:hover {
  color: #7D7D7D;
  border-color: #7D7D7D;
}


.btnlightgrey {
  font-size: 16px;
  font-weight: 500;
  line-height: 38px;
  padding: 0px 16px;
  border-radius: 5px;
  color: var(--color-white);
  font-family: var(--family1);
  background: var(--primary-color);
  letter-spacing: 0.3px;
  border: 2px solid var(--primary-color);
}

.btnlightgrey:hover {
  color: var(--color-white);
  border-color: #646E89;
  background: #646E89;
}


.btnDanger {
  font-size: 16px;
  font-weight: 500;
  line-height: 38px;
  padding: 0px 16px;
  border-radius: 5px;
  color: var(--color-white);
  font-family: var(--family1);
  background: var(--red);
  letter-spacing: 0.3px;
  border: 2px solid var(--red);
}

.btnDanger:hover {
  color: var(--color-white);
  border-color: #E53F3F;
  background: #E53F3F;
}

.btnWarning {
  font-size: 16px;
  font-weight: 500;
  line-height: 38px;
  padding: 0px 16px;
  border-radius: 5px;
  color: var(--color-white);
  font-family: var(--family1);
  background: var(--pending);
  letter-spacing: 0.3px;
  border: 2px solid var(--pending);
}

.btnWarning:hover {
  color: var(--color-white);
  border-color: #ECC90F;
  background: #ECC90F;
}


.btnDisabled {
  font-size: 16px;
  font-weight: 500;
  line-height: 38px;
  padding: 0px 16px;
  border-radius: 5px;
  color: var(--color-white);
  font-family: var(--family1);
  background: #DDDDDD;
  letter-spacing: 0.3px;
  border: 2px solid #DDDDDD;
}

.btnDisabled:hover {
  color: var(--color-white);
  border-color: #D1D1D1;
  background: #D1D1D1;
}



.btnSmall {
  line-height: 24px !important;
  padding: 0px 12px;
  min-width: auto;
  font-size: 13px;
  border-radius: 5px;
  letter-spacing: 0px;
  font-weight: 500;
  font-family: var(--family1);
}

.btnSmall svg {
  width: 18px;
  height: auto;
}


.backText {
  position: relative;
  bottom: -2px;
  left: 14px;
  gap: 5px !important;
}

.backText svg {
  width: 13px;
  height: auto;
}

.backText h5 {
  font-size: 13px;
  font-weight: 500;
}

.formLabel {
  color: #6C7692;
  font-size: 14px;
  display: block;
  font-weight: 400;
}

label.MuiTypography-label {
  color: #6C7692;
  font-size: 14px;
  display: block;
  font-weight: 400;
}

hr {
  margin: 0px !important;
  border-top: 1px solid #aaa !important;
  width: 100%;
  height: auto;
}

.assRight>div {
  padding: 10px 1.5rem;
  background: #ffffff;
}

.assRight>div:nth-child(2n) {
  background: #f9f9f9;
}

.assRight>div>p {
  font-size: calc(var(--fs16) - 1px);
  font-weight: 500;
  font-family: var(--family2) !important;
  color: var(--color-black);
}

.assRight>div>div .form-check label {
  color: var(--primary-color);
  font-size: var(--fs14);
  font-weight: 400;

}





.toogleBtn span.MuiSwitch-switchBase.Mui-checked {
  color: var(--color-blue);
}

.toogleBtn span.MuiSwitch-switchBase {
  color: var(--error);
}

.toogleBtn .MuiSwitch-track {
  background-color: rgba(170, 170, 170, 0.3) !important;
  opacity: 1 !important;
}



.containerFluid {
  padding: 0px 0px 30px 0px;
}

.upldImg {
  overflow: hidden;
}

.upldImg svg {
  height: 190px;
  width: 190px;
}

.actBtnGrps {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.verifyBtn {
  font-size: 12px !important;
  font-weight: 500;
  color: var(--color-blue) !important;
  cursor: pointer;
  letter-spacing: 0px;
}

.verifyBtn:hover {
  text-decoration: underline;
}

.editIcon,
.viewIcon,
.deleteIcon {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
}

.editIcon:hover,
.viewIcon:hover,
.deleteIcon:hover {
  text-decoration: underline;
}

.editIcon {
  color: var(--primary-color);

}

.viewIcon {
  color: var(--color-blue);
}

.deleteIcon {
  color: var(--error);
}

.editIcon path {
  fill: var(--primary-color);
}

.viewIcon path {
  fill: var(--color-blue);
}

.deleteIcon path {
  fill: #EF3F3F;
}

.platBlocks label.MuiFormControlLabel-root {
  background: #f5f5f5;
  padding: 0px 15px;
  border-radius: 5px;
  margin: 0px 10px 0px 0px;
  height: 45px;
}

.platBlocks label.MuiFormControlLabel-root:last-child {
  margin: 0px;
}

textarea {
  padding: 14px !important;
  line-height: 22px !important;
  font-size: 14px !important;
  height: auto !important;
  min-height: 80px !important;
  max-height: 80px;
  width: 100%;

}

.rolesSearch {
  margin-right: 0px !important;
}

.textField fieldset {
  padding: 15px;
}



.rsw-editor {
  min-height: 140px !important;
  border: 1px solid #aaa !important;
  background: var(--color-white) !important;
}


/* Login Page */
.formSide {
  min-height: 100vh;
  padding: 60px 0px;
}

.formSide .tagline {
  font-size: var(--fs16);
  font-weight: var(--fw500);
  color: var(--primary-color);
  font-family: var(--family1);
  margin-bottom: calc(10px - 5px);

}

.formSide h1 {
  font-size: var(--fs24);
  font-weight: var(--fw700);
  color: var(--color-black);

}

.afUpload {
  padding: 0px 15px !important;
  font-family: 'Inter';
  letter-spacing: 0px;
  font-size: 14px !important;
  box-shadow: none !important;
  line-height: 33px !important;
  text-transform: capitalize;
  background: rgba(170, 170, 170, 0.5) !important;
}



.textGradient {
  background: #439CFB;
  background: radial-gradient(circle farthest-corner at top left, #439CFB 0%, #F187FB 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fromContainer {
  width: 350px;
}

.fromContainer .logoIcon {
  margin-bottom: 10px;
}



.formContainerSignup {
  max-width: 550px;
  width: 40%;
}

.formContainerSignUp .logoIcon img {
  height: 55px;
}

form .form-label {
  font-size: calc(var(--fs14) - 1px);
  font-weight: 600;
  color: var(--color-black);
}

.frgtlink {
  color: var(--color-blue);
  font-weight: var(--fw600);
  font-size: calc(var(--fs14) - 1px);
}

.form-check>label {
  font-size: var(--fs14);
  color: var(--color-black);
  font-weight: var(--fw500);
}

button.MuiButtonBase-root svg {
  width: 28px;
  height: auto;

}

button.MuiButtonBase-root svg path {
  fill: var(--primary-color);
  opacity: 0.8;
}

button.MuiButtonBase-root.Mui-selected svg path {
  fill: var(--color-white);
  opacity: 1;
}



.redirectLink {
  font-weight: var(--fw500);
}

.policyLink a {
  display: inline-block;
  padding: 0px 15px;
  border-right: 1px solid #ededed;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: var(--fw500);
  text-decoration: none;
}

.policyLink a:hover {
  color: var(--color-blue);
  font-weight: var(--fw500);
  text-decoration: none;
}


.policyLink a:last-child {
  padding-right: 0px;
  border-right: 0px;
}

.signUpscreen {
  padding: 0rem 4rem;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
}

input::-ms-input-placeholder {
  /* IE 10+ */
  color: #000;
}

input::-moz-placeholder {
  /* Firefox 18- */
  color: #000;
}

.bgDiv {
  height: 100vh;
  padding: 1rem 0 1rem 1rem;
}

.lftSide {
  background-image: url(../src/assets/images/onboarding/onboardBG.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  border-radius: var(--radius8);
}



.carousel1 {
  max-width: 350px;
}

.carousel1 .carousel-caption h3 {
  font-size: var(--fs24);
  line-height: var(--linenormal);
  margin-bottom: var(--fs12);
  color: var(--color-white);
  font-weight: var(--fw600);
}

.carousel1 .carousel-indicators {
  bottom: -40px;
}

.carousel1 .carousel-indicators button {
  width: var(--fs16);
  height: 4px;
  border-radius: var(--fs10);
  background: var(--color-white);
  border: 0px;
}

.compnyDet .MuiBox-root.css-19kzrtu {
  padding: 0px;
}

span.label {
  font-weight: bold;
  font-size: 15px;
}

.showhandCursor {
  cursor: pointer;
}

.showhandCursor>div {
  border: 1px solid #ccc;
  width: 100%;
  min-height: 154px;
  border-radius: 16px;
  background: #fff;
  position: relative;
  padding: 16px 20px;
}

.showhandCursor>div:hover {
  border: 1px solid var(--color-blue);
}

.showhandCursor>div h4 {
  font-size: 18px;
  margin-top: 11px;
}

.showhandCursor>div h6 img {
  height: 60px;
  width: auto;
}

.showhandCursor>div.campaignActive {
  border: 2px solid #0077FF;
  width: 100%;
  height: 135px;
  border-radius: 16px;
  background: #0077FF;

}

.showhandCursor>div.campaignActive>img {
  position: absolute;
  right: 10px;
  width: 22px;
  height: 22px;
  top: 10px;
}

.showhandCursor>div.campaignActive h4 {
  color: #fff;
}


.campCat .catType:hover {
  border-color: var(--color-blue) !important;
}

.catType.active {
  border-color: var(--color-blue) !important;
}


.campBtns>button {
  line-height: 48px;
  border-radius: 50px;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--color-black);
  font-family: var(--family2);
  font-weight: 400;
}

.campBtns>button svg {
  width: 32px;
  height: auto;
}

.campBtns>button.btnPrimary {
  border-width: 2px;
  border-color: var(--color-blue);
  background: transparent;
}





.stepAcc>div {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(170, 170, 170, 0.3) !important;
  margin: 0px !important;
}

.stepAcc>div:last-child {
  border-bottom: 0px !important;
}

.stepAcc>div .MuiButtonBase-root {
  padding: 0px;
  height: auto;
  min-height: auto;
}

.stepAcc>div .MuiButtonBase-root .MuiAccordionSummary-content {
  margin: 0px;
}

.stepAcc>div .MuiButtonBase-root .MuiAccordionSummary-content>p {
  color: #000000;
  font-size: 18px;
  font-family: var(--family2) !important;
  font-weight: 600;
  width: 100%;
  padding: 15px 0px;
}

.stepAcc>div .MuiButtonBase-root .MuiAccordionSummary-content>p>p {
  font-weight: 600;
  width: 100%;
  font-size: 18px;
}

.stepAcc>div:before {
  content: none !important;
}

.previewElem {
  position: absolute;
  top: 42px;
  padding: 0px 22px;
  max-height: 632px;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow: hidden;
}

.previewElem label.MuiFormLabel-root {
  font-size: 13px !important;
  margin-bottom: 3px;
}

.MuiFormLabel-root.Mui-focused {
  color: var(--primary-color);
}


.stepAcc>div .MuiCollapse-root .MuiAccordionDetails-root {
  padding: 0px 0px 25px !important;
}

.stepOneSocial label.MuiFormLabel-root+div+div {
  padding: 24px 0px;
}

.stepOneSocial label.MuiFormLabel-root+div>div {
  min-height: 32px;
}

.listView .MuiListItemText-root span.MuiTypography-root {
  font-weight: var(--fw500);
}

.listView .MuiListItemSecondaryAction-root span.MuiTypography-root {
  font-weight: var(--fw400);
}

.MuiFormControlLabel-root {
  margin-left: 0px !important;
}


.formLevels .MuiStepper-root .MuiStep-root .MuiStepLabel-iconContainer svg {
  font-size: 32px;
  font-family: var(--family2);
}

.formLevels .MuiStepper-root .MuiStep-root .MuiStepLabel-labelContainer span.MuiStepLabel-label {
  font-family: var(--family2);
  font-size: var(--fs16);
  font-weight: var(--fw600);
}

.MuiStepLabel-iconContainer.Mui-active+span.MuiStepLabel-labelContainer span {
  color: var(--color-blue);
}

.MuiStepLabel-label.Mui-active {
  color: var(--color-blue);
}

.formLevels .MuiStepper-root .MuiStep-root .MuiStepLabel-labelContainer span.MuiStepLabel-label.Mui-completed {
  color: var(--color-blue);
}

.multiSelect {
  background: #fff;
  max-height: 250px;
  min-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0px !important;
  border-radius: 10px;
  border: 1px solid rgba(170, 170, 170, 0.5);
}

.multiSelect li {
  padding: 0px;
  margin: 0px;
}

.multiSelect li>div {
  padding: 0px;
  margin: 0px;
}

.multiSelect li>div>span {
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #5c5c5c;
  padding: 0px 15px;
}

.css-4857is-MuiListItem-root {
  color: var(--color-blue);
}

.multiSelect::-webkit-scrollbar {
  width: 5px;
}

.multiSelect::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(170, 170, 170, 0.1);
}

.multiSelect::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.cursorPointer {
  cursor: pointer;
}

.campPreview span.MuiStepLabel-iconContainer svg {
  font-size: 32px;
  color: var(--primary-color);
}

.campPreview span.MuiStepLabel-iconContainer+span.MuiStepLabel-labelContainer span {
  font-family: var(--family2);
  font-size: var(--fs18);
  color: var(--primary-color);
  font-weight: var(--fw700);
}

.campPreview .MuiStep-root .MuiStepContent-root {
  padding-left: 27px;
  margin-top: -8px;
}

.campPreview .MuiStep-root .MuiStepContent-root p {
  font-size: 14px !important;
}

.screenDetails h4 label {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  margin-right: 7px;
}

.screenDetails h4 span {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 7px;
}

.campDetailPrev h6 {
  font-size: 16px;
  font-weight: 500;
  color: #5c5c5c;
  letter-spacing: 0px;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}

.campDetailPrev h6>span.label {
  color: #000;
  font-weight: 600;
  min-width: 210px;
  font-size: 16px;
  display: inline-block;
}


.campDetailPrev h6>span:nth-child(2) {
  font-size: 16px;
  font-weight: 500;
  color: #5c5c5c;
  letter-spacing: 0px;
  margin-bottom: 0px;
  display: inline-block;
  width: calc(100% - 210px);
}

.MuiModal-backdrop {
  /* backdrop-filter: blur(2px); */
}


.apprvdInflu h6 {
  font-size: 15px;
  font-weight: 400;
  color: #000;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.apprvdInflu span.label {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  font-family: var(--family1);
  margin-right: 3px;
}



.nameLocation {
  gap: 24px;
}

.influImg {

  overflow: hidden;
  width: 110px;
  height: 110px;

}

.influImg img {
  width: 110px;
  height: 110px;
  border-radius: 8px;
  border: 1px solid rgba(170, 170, 170, 0.3);
  display: block;
}

.influDet {
  width: calc(100% - 135px);
}



.influDet .badges {
  flex-wrap: wrap;
}

.influDet h2 {
  font-size: 18px;
  color: #000000;
  font-weight: 700;
}

.badges {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.badges span.badge {
  background: #F1F4F8 !important;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  font-family: var(--family1);
  line-height: 24px;
  padding: 0px 10px;
  border-radius: 30px;
}

.influLocation {
  font-size: 14px;
  font-weight: 500;
  gap: 6px;
  color: var(--primary-color);
}

.influLocation svg {
  font-size: 18px;
  color: var(--primary-color);
}

.influLocation svg path {
  fill: var(--primary-color);
}

.tabsLayout .mainTabs button {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--family1);
  padding: 0px 16px 0px 12px;
  letter-spacing: 0.3px;
  min-height: 55px;
  color: var(--primary-color);
}

.tabsLayout .mainTabs button.Mui-selected {
  background: var(--color-blue);
  color: var(--color-white);
  border-radius: 10px;
}



.mainTabs {
  background: #f9f9f9;
}

.mainTabs span.MuiTabs-indicator {
  display: none;
}

.tabInnLayout .nav-tabs {
  border: 0px;
}

.tabInnLayout .nav-tabs li button {
  border: 0px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  padding: 0px;
  line-height: 45px;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

.tabInnLayout .nav-tabs li button svg {
  width: 20px;
  height: auto;
}

.tabInnLayout .nav-tabs li button.active {
  color: #0077FF;
}

.borderRight {
  border-right: 1px solid rgba(170, 170, 170, 0.3);
}

.progSection {
  width: 100%;
  border: 1px solid #E4E4E4;
  border-radius: 10px;
  padding: 24px;
}

.progressbarList {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.progressbarList>.barListItem {
  width: 46%;
}

.progressbarList>.barListItem>p {
  position: relative;
  display: inline-block;
  bottom: -5px;
}

.table_data tbody tr td:first-child,
.table_data tbody tr th:first-child {
  border-right: 1px solid rgba(170, 170, 170, 0.3) !important;
  border-top: 1px solid rgba(170, 170, 170, 0.3) !important;
  border-bottom: 0px solid rgba(170, 170, 170, 0.3) !important;
}

.table_data tbody tr td,
.table_data tbody tr th {
  border-top: 1px solid rgba(170, 170, 170, 0.3) !important;
  border-bottom: 0px solid rgba(170, 170, 170, 0.3) !important;
}

.table_data tbody tr td,
.table_data tbody tr th {
  background: transparent !important;
}


.followItems {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.followItems .item {
  width: 100%;
  border: 1px solid #E4E4E4;
  border-radius: 10px;
  padding: 24px;
}

.followItems .item h4 {
  color: rgba(0, 0, 0, 0.9);
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 20px;
}

.followItems .item ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
}

.followItems .item ul li {
  list-style: none;
}

.followItems .item ul li label,
.followItems>label {
  display: block;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 5px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.followItems .item ul li span,
.followItems>span {
  display: block;
  font-size: 18px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.bgOne {
  background: #FAF8FD;
}

.bgTwo {
  background: #FFFAFA;
}

.bgThree {
  background: #F8FCFF;
}

.bgFour {
  background: #F8FBFF;
}

.newSec {
  width: 100%;
}

.newSec>h4 {
  font-size: 18px;
  font-weight: 600;
}

.newSec>h4 {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 25px;
}

.secItem {}

.secItem:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: 0px solid rgba(170, 170, 170, 0.1);
}

.secItem h4 {
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Inter", sans-serif;
  margin-bottom: 15px;
}

.secItem .followItems .item {
  /* border: 0px;
  padding: 0px; */
}

.imgBlock .MuiGrid-item>div.MuiCard-root {
  box-shadow: none;
  border: 1px solid rgba(170, 170, 170, 0.7);
  border-radius: 0px;
  padding: 20px 16px;
  text-align: center;
}

.imgBlock .MuiGrid-item>div.MuiCard-root>img {
  width: 90px;
  height: 90px;
  margin: 0 auto;
}

.imgBlock .MuiGrid-item>div.MuiCard-root h4 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  font-family: Gilmer;
}

.imgBlock .MuiGrid-item>div.MuiCard-root h4 span {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
}


.imgBlock .MuiGrid-item>div.MuiCard-root .MuiCardActions-root a {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  color: var(--primary-color);
}

.appInfluPop caption.MuiTypography-root a {
  font-size: 13px;
  line-height: 20px;
  display: block;
  font-weight: 500;
  word-wrap: break-word;
}


.sumOptions a {
  border: 0px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-family: var(--family1);
  font-weight: 400;
  padding: 0px;
  line-height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  text-decoration: none;
  color: var(--color-black);
}

.campSerchInflu {
  background-image: url(../src/assets/images/gradientBg2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.campSerchInflu h4 {
  /* font-weight: var(--fw500); */
}

.campSerchInflu h4 span {
  background: #FFCC70;
  background: linear-gradient(to right, #FFCC70 0%, #C850C0 50%, #4158D0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: var(--fw700);
}

.searchPlatform {
  border-radius: 50px 0px 0px 50px;
}

.searchPlatform .MuiInputBase-formControl .MuiOutlinedInput-input {
  line-height: 45px;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-color);
}

.searcPage .searchBlockInflu {
  bottom: 0px;
}

.searchBlockInflu {
  background: #fff;
  border-radius: 5px;
  height: auto;
  position: relative;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  padding: 12px;
  bottom: -33px;
}

.searchBlockInflu .searchBtn {
  line-height: 45px;
  border: 0px;
  width: 135px;
  border-radius: 5px;
  padding: 0px;
  background: rgb(76, 201, 240);
  background: linear-gradient(49deg, rgba(76, 201, 240, 1) 0%, rgba(58, 12, 163, 1) 33%, rgba(247, 37, 133, 1) 67%, rgba(114, 9, 183, 1) 100%);
  color: var(--color-white);
  font-size: 16px;
  font-weight: var(--fw500);
  font-family: var(--family2);

}

.searchBlockInflu .searchBtn svg {
  height: 30px;
  width: auto;
}

.searchBlockInflu .searchBtn svg path {
  fill: var(--primary-color);
}

.searchPlatform .MuiInputBase-formControl {
  padding: 0px 0px 0px 15px;
  font-family: var(--family1);
  background: transparent;
  letter-spacing: 0px;
  border: 0px;
  box-shadow: none;
  background: #F4F8FF;
  border-radius: 0px;
}

.searchPlatform .MuiInputBase-formControl fieldset {
  border: 0px;
}

.searchText .MuiInputBase-formControl {
  padding: 0px !important;
}

.searchText .MuiInputBase-formControl fieldset {
  border: 0px;
}

.searchText .MuiInputBase-formControl input {
  line-height: 45px;
  padding: 0px 15px !important;
  font-family: var(--family1);
  font-size: 16px;
  font-weight: 500;
  color: var(--color-black);
  height: auto !important;
}


.searchText .MuiGrid-root.css-vj1n65-MuiGrid-root {
  display: flex;
  flex-wrap: wrap;
}




.commItem {
  background-image: url(../src/assets/images/commuItem1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.imgwplatform {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  bottom: 0px;
  right: 0px;
  left: 0px;
  padding: 16px;
  position: absolute;
}

.brandProfImg {
  display: flex;
}

.brandProfImg img {
  width: 30px;
  height: 30px;
  transform: scale(1.2);
  border: 1px solid #fff;
  border-radius: 100px;
}


.lastMrgin:last-child {
  margin-bottom: 0px;
}

.appInfluPop1 caption.MuiTypography-root a {
  display: flex;
  gap: 10px;
  align-items: center;
}


.dashCard {
  -webkit-box-shadow: 0px 15px 29px -19px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 15px 29px -19px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 15px 29px -19px rgba(0, 0, 0, 0.1) !important;
}

.dropmenu .MuiMenu-paper {
  background: transparent;
  box-shadow: none;
  right: 14px;
  left: auto !important;
  width: 200px;
  padding-top: 14px;
}

.dropmenu ul.MuiList-padding {
  padding: 0px;
  border: 1px solid rgba(170, 170, 170, 0.5);
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
}


.dropmenu ul.MuiList-padding li {
  background: var(--color-white);
  padding: 10px 10px 10px 10px;
  width: 100%;
}

.dropmenu ul.MuiList-padding li p {
  font-weight: var(--fw400);
  font-size: var(--fs14);
  color: var(--primary-color);
}

.dropmenu ul.MuiList-padding li svg {
  width: 20px;
  height: auto;
}

.dropmenu ul.MuiList-padding li svg path {
  fill: var(--primary-color);
}

.dropmenu ul.MuiList-padding li:hover {
  background: rgba(0, 119, 255, 0.10) !important;
  color: var(--color-blue);
}




.userNme {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.userNme h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  font-family: var(--family1) !important;
  text-transform: capitalize;
}

.userNme label {
  color: var(--green);
  font-size: var(--fs12);
  text-transform: capitalize;
}



label[for="image-upload"] {
  cursor: pointer;
}

.stepAcc label[for="image-upload"] button.MuiButtonBase-root.MuiIconButton-root {
  padding: 8px;
}


#image-upload+label>svg {
  width: 50px;
}

input#image+label[for="image-upload"]>img {
  border: 5px solid transparent;
  border-radius: 10px !important;
  height: 100% !important;
}


.createNew {
  border: 0.5px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 222px !important;
}

.createNew h4 {
  font-weight: normal;
  font-size: 22px;
  margin-top: 10px;
}

.createNew svg {
  width: 35px;
  height: auto;
}

.searchInput,
.searchInput input {
  font-family: var(--family1) !important;
}



.inputSearch:hover fieldset {
  border-color: #aaa !important;
}

.searchInput fieldset.MuiOutlinedInput-notchedOutline {
  border-color: rgba(170, 170, 170, 1);
}

.searchInput.inputSearch>div {
  padding: 0px;
}

.sContrl>div.MuiOutlinedInput-root {
  height: 40px;
  border-radius: 5px;
}



.sContrl>div.MuiOutlinedInput-root input,
.sContrl .MuiInputAdornment-root input {
  font-family: var(--family1) !important;
  color: var(--color-black);
  padding-left: 10px !important;
}


.sContrl>div.MuiOutlinedInput-root fieldset {
  border: 1px solid rgba(170, 170, 170, 0.3) !important;
}

.sContrl>div.MuiOutlinedInput-root .MuiInputAdornment-root {
  background: #f9f9f9;
}

.sContrl .MuiInputAdornment-root {
  background: #f9f9f9;
}

.sContrl>div.MuiOutlinedInput-root .MuiInputAdornment-root svg path {
  fill: var(--primary-color);
}


.overIcon {
  width: 45px;
  justify-content: center;
  background-color: transparent;
  border-right: 0px solid rgba(170, 170, 170, 0.3);
  border-radius: 8px 0px 0px 8px;
}

.overIcon+input {
  padding-left: 0px !important;
  font-family: var(--family1);
}

.overIcon svg {
  width: 20px;
  height: auto;
}

.overIcon svg path {
  fill: var(--primary-color);
  opacity: 0.8;
}

.css-o0rhj7-MuiFormHelperText-root.Mui-error,
.css-1vcahvl-MuiFormHelperText-root.Mui-error,
.css-v5zlt0-MuiFormHelperText-root.Mui-error {
  color: var(--error) !important
}

.bidFlows>div.MuiGrid-item {
  background: rgba(127, 76, 199, 5%);
  border-radius: 10px 0px 0px 10px;

}



.bidFlows>div.MuiGrid-item:nth-child(2n) {
  border-radius: 0px 10px 10px 0px;
}

.MuiStepConnector-lineHorizontal {
  border-color: #aaa !important;
  border-top-style: dashed !important;
  border-top-width: 2px !important;
  margin-top: 3px;
}

.MuiStep-alternativeLabel.Mui-completed+.MuiStep-alternativeLabel span.MuiStepConnector-line.MuiStepConnector-lineHorizontal {
  border-color: var(--color-blue) !important;
}

.progLinebar {
  background-color: #ccc !important;
}

.progLinebar>span {
  background-color: var(--color-blue) !important;
}

.Average {
  /* // background-color:; */
  background-color: #c78c06 !important;
  color: white !important;
  border-radius: 4px !important;
}

.Low {
  background: red !important;
  color: white !important;
  border-radius: 4px !important;
}

.Good {
  background: green !important;
  color: white !important;
  border-radius: 4px !important;
}

/* 
.tabsLayout .mainTabs button {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--family1);
  padding: 0px 20px;
  letter-spacing: 0px;
  min-height: 55px;
  border: 1px solid gray;
} */


.brandBlocks>div {
  box-shadow: none;
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 18px;
}

.brandBlocks>div {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.brandBlImg .imgCver {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fcfcfc;
  padding: 5px;
}

.brandBlImg>div img {
  max-width: 90%;
  height: auto;
}






.MuiTableContainer-root::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

.MuiTableContainer-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: rgba(170, 170, 170, 0.4);
  outline: 1px solid rgba(170, 170, 170, 0.4);
}

.cursorDisabled {
  color: 'gray';
  text-decoration: 'none';
  cursor: 'not-allowed'
}

img[src="/accept.png"],
img[src="/remove.png"] {
  cursor: pointer;
}

img[src="/plus.png"],
img[src="/minus.png"] {
  cursor: pointer;
}


.appInfluPop.appInfluPop1 .MuiOutlinedInput-input.Mui-disabled,
.appInfluPop.appInfluPop1 .MuiOutlinedInput-input {
  background: #f9f9f9;
  border-color: #eee;
}


/* Status Badges */

.approved,
.accepted,
.active,
.delivered,
.completed,
.closed {
  line-height: 25px;
  padding: 0px 8px;
  color: #22c55e;
  background: rgba(34, 197, 94, 0.10);
  font-family: var(--family1);
  border: 0px;
  border-radius: 4px;
  font-size: 13px;
  letter-spacing: 0.2px;
  font-weight: 400 !important;
  cursor: context-menu !important;

}

.rejected,
.failed,
.expired,
.disabled,
.removed,
.blacklisted {
  line-height: 25px;
  padding: 0px 8px;
  color: #ef3f3f;
  background: rgba(239, 63, 63, 0.10);
  font-family: var(--family1);
  border: 0px;
  border-radius: 4px;
  font-size: 13px;
  letter-spacing: 0.2px;
  font-weight: 400 !important;
  cursor: context-menu !important;
}

.unpublished,
.draft,
.review,
.submitted,
.rebid,
.negotiation,
.negotiated {
  line-height: 21px;
  padding: 0px 9px;
  color: var(--color-white);
  background: var(--pending);
  font-family: var(--family1);
  border: 0px;
  border-radius: 4px;
  font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: 400 !important;
  cursor: context-menu !important;
}



.pending {
  line-height: 25px;
  padding: 0px 8px;
  color: #e9c60d;
  background: rgba(255, 217, 15, 0.10);
  font-family: var(--family1);
  border: 0px;
  border-radius: 4px;
  font-size: 13px;
  letter-spacing: 0.2px;
  font-weight: 400 !important;
  cursor: context-menu !important;
}

.unpublished,
.draft,
.review {
  line-height: 25px;
  padding: 0px 8px;
  color: #9F9C9C;
  background: rgba(159, 156, 156, 0.10);
  font-family: var(--family1);
  border: 0px;
  border-radius: 4px;
  font-size: 13px;
  letter-spacing: 0.2px;
  font-weight: 400 !important;
  cursor: context-menu !important;
}



.live,
.success,
.sent {
  line-height: 25px;
  padding: 0px 8px;
  color: #22C55E;
  background: rgba(34, 197, 94, 0.10);
  font-family: var(--family1);
  border: 0px;
  border-radius: 4px;
  font-size: 13px;
  letter-spacing: 0.2px;
  font-weight: 400 !important;
  cursor: context-menu !important;
}

.imgUploadUI {
  position: relative;
  width: 100%;
  height: 110px;
  background: #fff;
  outline: 1px dashed rgba(170, 170, 170, 0.5);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.imgUploadUI>div {
  display: flex;
  flex-direction: column;
  color: var(--primary-color);
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 3px;
}

.imgUploadUI>div>svg {
  width: 30px;
  height: auto;
  opacity: 0.5;
}

.imgUploadUI>div>h5 {
  opacity: 0.4;
  letter-spacing: 0.2px !important;
  font-size: 14px;
  color: #000;
}

.imgUploadUI:hover {
  outline: 1px dashed rgba(170, 170, 170, 1);
}

.imgUploadUI:hover>div>svg {
  opacity: 1;
}

.imgUploadUI:hover>div>h5 {
  opacity: 1;
}

.MuiMenu-list li {
  font-size: 13px;
  padding: 5px 16px;
}

.fileNameblock {
  font-size: var(--fs14);
  font-weight: var(--fw600);
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.viewDetTabs {
  background: var(--color-white);
  height: 48px;
  padding: 5px !important;
  border-radius: 5px;
}

.viewDetTabs button {
  border: 0px;
  font-size: var(--fs14);
  color: var(--color-black);
  letter-spacing: 0px;
  background: transparent;
  padding: 0px 16px;
  font-weight: 500;
  border-radius: 5px;
}



.dashboardPage.viewDetTabs {
  width: fit-content;
}

.dashboardPage.viewDetTabs button {
  height: 100%;
}

.viewDetTabs button.active {
  background: var(--color-blue);
  color: var(--color-white);
}

.viewDetTabs button.active span {
  width: 32px;
  height: 22px;
  border-radius: 50px;
  display: inline-block;
  background: var(--color-white);
  color: var(--color-blue);
  margin-left: 8px;
  font-size: 11px;
  /* padding: 0px 5px; */
}



.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 3px !important;

}

.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-tag {
  position: relative;
  top: -1px;
}

.pieChart .apexcharts-canvas svg {
  background-color: transparent !important;
}

.react-datepicker-popper {
  z-index: 1000 !important;
  /* //8 !important; */
  line-height: 0;
}

.cFilter>div .react-datepicker-wrapper {
  width: 100%;
}

button.custom-input {
  height: 45px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: transparent;
  font-size: 13px;
  color: #555;
  width: 100%;
  padding: 0px 30px 0px 10px;
  text-align: left;
}

.communityBlock .react-multi-carousel-track {
  /* gap:24px; */
}

.crtecmp .react-multi-carousel-track li {
  padding: 0px 10px;
}

.crtecmp .react-multi-carousel-track li:first-child {
  padding-left: 0px;
}

/* .crtecmp  .react-multi-carousel-track li:first-child{
  max-width: 150px;
}

.communitiesBlock  .react-multi-carousel-track li:first-child{
  max-width: 100%;
} */


.crtecmp .react-multi-carousel-track li,
.dashCommunities .react-multi-carousel-track li,
.competitorBlock .react-multi-carousel-track li {
  padding: 0px 16px 0px 0px;
}

.crtecmp .react-multi-carousel-track li:first-child,
.dashCommunities .react-multi-carousel-track li:first-child,
.competitorBlock .react-multi-carousel-track li:first-child {
  padding-left: 0px;
}


.bidFlows .rounds {
  font-size: var(--fs22);
  font-weight: var(--fw600);
  font-family: var(--family1);
  color: var(--color-black);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--fs24);
  font-style: italic;
}

.bidFlows .rounds:after {
  content: "";
  height: 1px;
  background: var(--color-black);
  width: 86%;
  display: block;
}

.chatType {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  width: 45%;
}

.chatType .userImg {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #f1f1f1;
  display: block;
}

.chatArea {
  width: calc(100% - 70px);
  background: #0077FF;
  border-radius: 16px 16px 16px 0px;
  padding: 10px;
}

.amtHighlight {
  background: #fff;
  line-height: 36px;
  border-radius: 6px;
  padding: 0px 10px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}

.chatDesc {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0;
  width: 100%;
}

.chatDesc label {
  width: 25%;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.chatDesc .brief {
  width: calc(100% - 25%);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #fff;
}

.bidFlows>div:nth-child(2n) {
  margin-bottom: 24px;
}

.bidFlows>div:nth-child(2n) .row>div {
  justify-content: flex-end;
  display: flex;
}

.bidFlows>div:nth-child(2n) .rounds:after {
  content: none;
}

.bidFlows>div:nth-child(2n) .row .chatType {
  flex-direction: row-reverse;
}

.bidFlows>div:nth-child(2n) .row .chatType .chatArea {
  border-radius: 16px 16px 0px 16px;
  background: #eeeeee;
}

.bidFlows>div:nth-child(2n) .row .chatType .chatArea .chatDesc label,
.bidFlows>div:nth-child(2n) .row .chatType .chatArea .chatDesc .brief {
  color: #000;
}

.processScrolable {
  max-height: 388px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.processScrolable::-webkit-scrollbar {
  width: 0px;
}

.processScrolable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.processScrolable::-webkit-scrollbar-thumb {
  background-color: #ccc;
  outline: 0px solid slategrey;
}

.industNiches {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 16px;
}

.cmppge .react-datepicker-popper {
  left: -157px !important;
}

.cmppge .react-datepicker__triangle {
  left: auto !important;
  right: 68px;
}


.forScrollbarDesign::-webkit-scrollbar {
  width: 5px;
}

.forScrollbarDesign::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.forScrollbarDesign::-webkit-scrollbar-thumb {
  background-color: #ccc;
  outline: 0px solid slategrey;
}



.signupbg {
  background-image: url(../src/assets/images/onboarding/signupbg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 100vh;
}

.formcvr {
  background: #fff;
  max-width: 100%;
  width: 100%;
  border-radius: 16px;
  max-height: 95vh;
  overflow-y: scroll;
  padding: 24px 30px 20px 30px;
}

.formcvr::-webkit-scrollbar {
  width: 0px;
}

.formcvr::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.formcvr::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}


.formcvr .logoIcon img {
  height: 50px;
}

.formcvr .frm input {
  height: 40px;
  padding: 0px;
  font-size: 15px;
  color: #000;
}

.formcvr .frm input[type="checkbox"] {
  height: 18px;
  width: 18px;
  border: 1px solid #ccc;
}


.formcvr .frm fieldset {
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 1px solid #aaa;
  border-radius: 0px;
}


.signupbg .formSide {
  min-height: 100vh;
  padding: 30px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.maintagline {
  font-size: 32px;
  font-weight: 500;
  color: var(--color-white);
  margin-bottom: 32px;
}

.maintagline span {
  font-size: 28px;
  font-style: italic;
  font-weight: normal;
  display: block;
}

.reg_pointer {
  width: 100%;
  height: auto;
  padding: 0px;
  margin: 0px;
}

.reg_pointer li {
  list-style: none;
  color: var(--color-white);
  font-size: 34px;
  font-family: var(--family2);
  font-weight: 600;
}

.formcvr .frm .MuiGrid-item {
  margin-bottom: 16px;
}

.quesSection {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.questionPointers {
  width: 60%;
}

.questionImg {
  width: 38%;
}

.questionImg img {
  height: 250px;
}

span.totalcount {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-black);
}




.questionPops .MuiDialog-container>.MuiPaper-root {
  border-radius: 12px;
  box-shadow: none;
  padding: 40px;
  max-width: 768px;
  margin: 0px;
  height: 432px;
  background-image: url(../src/assets/images/companyQuestions/cardbg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.questionPops .MuiDialog-container>.MuiPaper-root h2.MuiTypography-root {
  letter-spacing: 0px !important;
  font-family: var(--family2) !important;
  font-size: var(--fs24);
  font-weight: var(--fw500);
  padding: 0px !important;
  margin-bottom: 32px;
}

.questionPops .MuiDialog-container .MuiDialogContent-root {
  padding: 0px;
}

.questionPointers label.MuiFormControlLabel-root {
  margin-bottom: 20px;
}

.quesPoint span.MuiButtonBase-root {
  color: var(--color-black);
  padding: 0px;
}

.quesPoint span.MuiTypography-root {
  color: #000;
  font-family: var(--family1);
  font-size: 16px !important;
  padding-left: 16px;
}

.quesPoint span.MuiButtonBase-root span>svg {
  width: 20px;
  height: 20px;
}

.questionImg img {
  max-width: 100%;
}

.fCapital {
  text-transform: capitalize;
}

.fCapital+button {
  text-transform: capitalize;
}


.multiselectFilter>div.MuiAutocomplete-root>div.MuiFormControl-root>div.MuiInputBase-root {
  height: auto !important;
}

.capitalize-first-letter {
  text-transform: lowercase;
}

.capitalize-first-letter::first-letter {
  text-transform: uppercase;
}

.progressbarList>.barListItem>p::first-letter {
  text-transform: uppercase;
}


.first:first-letter {
  text-transform: capitalize;
}

.upper-case-heading {
  text-transform: uppercase;
}

.content-tab {
  background: #F1F4F8;
}

.is-sticky {
  position: fixed;
  top: 0;
  left: "375px";
  width: 100%;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

.MuiInputBase-adornedStart {
  white-space: nowrap
}

.customHeading {
  font-family: Inter !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 33.89px !important;
}

.gradient-text {
  background: linear-gradient(180deg, #3273ff, #e7008a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 38px !important;
  font-style: Inter !important;
  font-weight: 600 !important;

}

.custom-pricing {
  background: linear-gradient(180deg, #3273ff, #e7008a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 34px !important;
  font-style: Inter !important;
  font-weight: 600 !important;
}

.get-plane {
  background: linear-gradient(180deg, #3273ff, #e7008a);
  text-align: center;
}

.plane-border {
  border: 1px linear-gradient(180deg, #3273ff, #e7008a);
}

.content-active-tab {
  padding: 6px;
  border-radius: 5px;
}

.packagePlans {
  padding-bottom: 40px;
}

.packagePlans .container-fluid {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  justify-content: center;
  gap: 20px;
}


.packagePlans .planItem {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  width: calc(19% - 16px);
}

.packagePlans .planItem label {
  font-size: 18px;
  color: #0077FF;
  text-align: center;
  display: block;
  margin-bottom: 4px;

}

.planItem h2 {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  font-family: var(--family1) !important;
  margin-bottom: 0px;
  background: linear-gradient(202deg, #4CC9F0 14.53%, #3A0CA3 37.05%, #F72585 59.91%, #7209B7 82.76%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
}

.planItem h2 span {
  font-weight: 400;
}

.get-plane-btn {
  height: 46px;
  padding: 0px !important;
  margin-top: 30px !important;
}

.get-plane-btn h4 {
  height: 46px;
  background: radial-gradient(205.06% 231.46% at 53.1% -53.26%, #4CC9F0 0%, #3A0CA3 33%, #F72585 66.5%, #7209B7 100%);
  width: 100%;
  color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--family1) !important;
}

.get-plane-btn>div {
  padding: 0px;
  margin: 0px;
}

.packageDetails {
  margin-top: 20px;
  background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/4px 4px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/4px 4px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/4px 4px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/4px 4px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 2px) calc(100% - 8px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 8px) calc(100% - 2px) no-repeat,
    linear-gradient(25deg, #4cc9f0 0%, #3a0ca3 33%, #f72585 67%, #7209b7 100%);

  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px 12px;
  height: fit-content;
  border-radius: 4px;
  min-height: 380px;
}

.MuiMultiSectionDigitalClockSection-root[aria-label="Select hours"]:after {
  height: auto;
}

.packageDetails p {
  font-family: var(--family1) !important;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  padding-bottom: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.packageDetails p:last-child {
  margin-bottom: 0px;
}

.packageDetails p svg {
  margin-right: 7px;
}


.subsPageTabs .viewDetTabs {
  border-radius: 8px;
  padding: 4px;
}

.subsPageTabs .viewDetTabs button {
  border-radius: 50px;
  background: #FFF;
  padding: 0px 17px;
  align-items: center;
  gap: 5px;
  font-family: var(--family1);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  /* 271.429% */
  text-transform: capitalize;
}

.recurrInfo {
  border-radius: 12px;
  border: 1px dashed #F6C000;
  background: #FFF8DD;
  display: flex;
  padding: 15px 18px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.recurrInfo h4 {
  color: var(--color-black);
  font-family: var(--family2);
  font-size: var(--fs20);
  font-style: normal;
  font-weight: var(--fw700);
  line-height: normal;
  margin-bottom: 10px;
}

.recurrInfo p {
  margin-bottom: 0px;
  font-family: var(--family1);
  font-size: var(--fs16);
  color: var(--color-black);
  font-weight: var(--fw400);
  line-height: var(--fs24);
}

.recurrInfo p span {
  font-weight: var(--fw600);
  color: var(--color-blue);
}

.subsPageTabs .viewDetTabs button.active {
  border-radius: 4px;
  background: radial-gradient(205.06% 231.46% at 53.1% -53.26%, #4CC9F0 0%, #3A0CA3 33%, #F72585 66.5%, #7209B7 100%);
}

.get-plane-btn._blank h4 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/4px 4px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/4px 4px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/4px 4px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/4px 4px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 2px) calc(100% - 8px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 8px) calc(100% - 2px) no-repeat,
    radial-gradient(#4cc9f0 0%, #3a0ca3 33%, #f72585 67%, #7209b7 100%);
  border-radius: 4px;
  padding: 1px;
  box-sizing: border-box;
}

.get-plane-btn._blank h4:hover {
  background: radial-gradient(205.06% 231.46% at 53.1% -53.26%, #4CC9F0 0%, #3A0CA3 33%, #F72585 66.5%, #7209B7 100%), #07F;
  color: #fff;
  border: 0px;
}

.cretePlan .borderBox {
  border: 1px solid rgba(170, 170, 170, 0.3);
  border-radius: 8px;
}

.cretePlan .borderBox h4 {
  padding: 20px;
  font-weight: var(--fw500);
  color: var(--color-black);
  font-size: var(--fs15);
  border-bottom: 1px solid rgba(170, 170, 170, 0.3);
}

.numbBox {
  padding: 20px;
}

.cntCard {
  background: #f9f9f9;
  border: 1px solid rgba(170, 170, 170, 0.3);
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
}

.ttldiscount {
  max-width: 206px;

}


.ttldiscount fieldset {
  border: 1px solid rgba(170, 170, 170, 0.3);
}

.ttldiscount>div {
  background: #f7f7f7;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7) !important;
}

.ttldiscount input {
  background: #fff;
  margin-left: 10px;
}

.discountTable .MuiTableContainer-root {
  background: transparent;
  box-shadow: none;
}

.discountTable thead tr th {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.7) !important;
  background: #f9f9f9 !important;
}

.discountTable tbody tr th {
  color: rgba(0, 0, 0, 0.7) !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.discountTable tbody tr td fieldset {
  border: 1px solid rgba(170, 170, 170, 0.2) !important;
}

.discountTable tbody tr td input {
  font-size: 15px !important;
  color: #000 !important;
  font-weight: 500 !important;
  text-align: center;
}

.discountTable tbody tr td {
  font-size: 15px !important;
  color: #000 !important;
  font-weight: 500 !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.myCampSliders .react-multi-carousel-track li:first-child {
  max-width: 200px;
}


.dataView {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dataView label {
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
  position: relative;
}

.dataView label:after {
  content: ":";
}

.dataView span {
  color: var(--primary-color);
}

.detCnt {
  font-size: var(--family1) !important;
}

.detCnt.Mui-disabled {
  border: 1px solid #aaa !important;
  color: #000 !important;
}

.detCnt:hover {
  border-width: 1px !important;
}

.detCnt svg {
  width: 20px !important;
}

.detCnt svg path {
  fill: var(--color-black) !important;
}

.influDetCnt h6 {
  margin: 0px;
  line-height: normal;
  font-size: 16px;
  font-weight: 500;
}

.cpyCls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.cpyCls svg {
  width: 18px;
  cursor: pointer;
}

.cpyCls svg:nth-child(2) {
  width: 20px;
  height: auto;
}

.cpyCls svg:nth-child(2) path {
  fill: #D2C6B5;
}

.cpyCls svg path {
  fill: #000;
}

.viewAmtBlcks {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 24px;
}


.viewAmtBlcks .amtblckItem {
  background-image: url(../public/blckBg.png);
  width: 275px;
  height: 155px;
  border-radius: 10px;
  box-shadow: 0px 0px 16px 0px #E8E8E8;
  background-size: cover;
  background-position: right bottom;
  background-color: #fff;
  padding: 18px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.viewAmtBlcks .amtblckItem h4 {
  font-size: 24px;
  font-family: var(--family1) !important;
  font-weight: 700;
  margin-bottom: 7px;
  word-wrap: break-word;
}

.viewAmtBlcks .amtblckItem p {
  font-family: var(--family1) !important;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0px;
  line-height: normal;
}

.viewAmtBlcks .amtblckItem span {
  font-family: var(--family1) !important;
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  position: relative;
  top: -5px;
}

.otherBal {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.otherBal>div h4 {
  font-size: 15px !important;
  margin-bottom: 2px !important;
  font-weight: 600 !important;
}

.otherBal>div p {
  font-size: 13px !important;
}

.campimg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.namedate a {
  display: block;
  font-weight: 500;
}

.namedate span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
}

.amtblckItem.addmny {
  display: flex;
  align-items: center;
  justify-content: center;

}


.actItem {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.actItem span {
  display: block;
  width: 100%;
}

.actItem img {
  height: 60px;
  width: auto;
  margin: 0px auto 12px;
}

.actItem h4 {
  color: var(--color-black);
  font-size: var(--fs28);
  font-weight: var(--fw600);
  margin-bottom: var(--fs10);
}

.actItem p {
  margin-bottom: 0px;
  font-size: 14px;
  color: #000;
}

.influDetphEmItem {
  display: flex;
  align-items: center;
}

.influDetphEmItem label {
  font-weight: 600;
  width: 80px;
  font-size: 15px;
}

.influDetphEmItem span {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
}


.appnrejbtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.talkCover {
  padding: 32px;
  border-radius: 12px;
}

.popHead {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.popHead svg {
  width: 20px;
  height: auto;
}

.popHead h4 {
  color: var(--color-black);
  font-size: var(--fs32);
  font-weight: var(--fw600);
  margin-bottom: 0px;
}

.popHead h4 span {
  background: radial-gradient(122.13% 106.55% at 72.21% 19.32%, #2ADDF6 0%, #474EA2 45.5%, #002083 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.popHead h4+p {
  font-size: 14px;
  color: #000;
  margin-top: 10px;
}

.talkinput fieldset {
  border-width: 0px 0px 1px 0px;
  border-radius: 0px;
  padding: 0px;
}

.talkinput input {
  padding: 0px !important;
}

.talkinput textarea {
  height: 60px !important;
  padding: 10px 0px 0px 0px !important;
}

.rsw-editor {
  border: 1px solid #ddd !important;
  /* border-radius: .375rem; */
  display: flex;
  flex-direction: column;
  min-height: 100px;
  overflow: hidden;
  background: white !important;
  /* //box-shadow: 1px 1px 0px 0px gray !important */
}

.rsw-toolbar {
  align-items: center !important;
  background-color: white !important;
  border-bottom: 2px solid #ddd !important;
  display: flex !important;
  width: 100% !important;
  padding-top: 8px !important;
  padding-bottom: 7px !important;
  gap: 10px
}

.rsw-dd {
  display: none !important;
}

.callBackMsg {
  background: var(--color-white);
  border-radius: var(--fs12);
  border: 1px solid #aaa;
  padding: 45px;
  max-width: 560px;
  min-width: 560px;
  height: auto;
  text-align: center;
}

.callBackMsg img {
  height: 80px;
  width: auto;
  margin-bottom: 30px;
}

.callBackMsg h4 {
  font-size: var(--fs28);
  color: var(--color-black);
  font-family: var(--family1);
  font-weight: var(--fw600);
  letter-spacing: 0.3px;
  margin-bottom: 30px;
}

.callBackMsg p {
  font-size: var(--fs20);
  color: rgba(0, 0, 0, 0.6);
  font-family: var(--family1);
  font-weight: var(--fw500);
  letter-spacing: 0.3px;
  margin-bottom: 30px;
}


.css-1a9idz4-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #aaa !important;
}

.css-10o2lyd-MuiStack-root {
  padding-top: 0px !important;
}

.keyPerfindi {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.keyPerfindi .MuiFormControl-root {
  width: 44%;
}


.labeling {
  position: relative;
}

.labeling>svg {
  position: absolute;
  top: 4px;
  right: -18px;
}

.labeling label {
  margin-right: 5px;
}

.keyPerfindi span.MuiButtonBase-root.MuiRadio-root {
  padding: 0px;
}

.keyPerfindi>div:first-child,
.keyPerfindi>label:first-child {
  width: 43%;
  margin: 0px;
}



/* Responsive Layout */



@media only screen and (min-width:1280px) {
  .slideContainer>img {
    max-width: 90%;
  }
}

@media only screen and (min-width:1400px) {
  .packagePlans .planItem {
    width: calc(19% - 16px);
  }
}

@media only screen and (max-width:1399px) {
  .myCmpny {
    gap: 16px;
  }

  .keyPerfindi>div:first-child,
  .keyPerfindi>label:first-child {
    width: 100%;
  }

  .keyPerfindi>div:first-child,
  .keyPerfindi>label:first-child {
    margin-bottom: 5px;
  }

  .labeling>svg {
    position: static;
  }

  .packagePlans .planItem {
    width: calc(24% - 16px);
  }

  .viewAmtBlcks .amtblckItem {
    width: 225px;
  }

  .cFilter {
    width: 100%;
    justify-content: flex-start;
  }

  .cFilter>div {
    min-width: 100px;
  }

  .keyPerfindi .MuiFormControl-root {
    width: 95%;
  }
}

@media only screen and (max-width:1299px) {
  .viewAmtBlcks .amtblckItem {
    width: calc(50% - 12px);
  }

  .keyPerfindi {
    padding-right: 0px;
  }

  .keyPerfindi .MuiFormControl-root {
    width: 95%;
  }

  .keyPerfindi>div:first-child,
  .keyPerfindi>label:first-child {
    margin-bottom: 5px;
  }
}



@media only screen and (max-width:1279px) {
  .slideContainer>img {
    max-width: 80%;
  }

  .signUpscreen {
    padding: 0rem 3rem;
  }

  .wishDate {
    flex-wrap: wrap;
  }

  .wishDate p {
    width: 100%;
  }

  .followItems .item ul {
    gap: 20px;
  }

  .packagePlans .planItem {
    width: calc(290px - 16px);
  }

  .planItem h2 {
    font-size: 32px;
  }

  .viewAmtBlcks .amtblckItem {
    width: calc(50% - 12px);
  }

  .keyPerfindi>div:first-child,
  .keyPerfindi>label:first-child {
    margin-bottom: 5px;
  }

}

@media only screen and (max-width:1199px) {
  .slideContainer>img {
    max-width: 80%;
    margin-bottom: 20px;
  }

  .carousel1 .carousel-caption h3 {
    font-size: var(--fs22);
  }

  .carousel1 .carousel-caption p {
    margin-bottom: 0px;
  }

  .signUpscreen {
    max-width: 450px;
    padding: 0rem 0rem;
  }

  .cFilter>div {
    min-width: 95px;
  }

  .brandBlocks>div {
    padding: 10px;
  }

  .keyPerfindi>div:first-child,
  .keyPerfindi>label:first-child {
    margin-bottom: 5px;
  }

  .followItems .item ul {
    gap: 24px;
  }

  li.smallLabel {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 7px;
  }

  li.smallLabel label {
    margin: 0px !important;
  }

  .apprvdInflu {
    gap: 0px !important;
  }

  .apprvdInflu h6 {
    margin-right: 30px;
  }

  .packagePlans .planItem {
    width: calc(32% - 16px);
  }

  .planItem h2 {
    font-size: 32px;
  }

  .reg_pointer li {
    font-size: 24px;
  }

}

@media only screen and (max-width:991px) {
  .backBtnHeight {
    min-height: auto !important;
  }

  .packagePlans .planItem {
    width: calc(45% - 16px);
  }

  .planItem h2 {
    font-size: 28px;
  }

  .signUpscreen {
    max-width: 80% !important;
    padding: 0rem 0rem;
    margin: 0 auto !important;
  }

  .sidebar {
    left: -100% !important;
  }

  .sidebar.activeSidebar {
    left: 0px !important;
  }

  .bodyArea {
    margin-left: 0px !important;

  }

  .sidebar.activeSidebar {
    left: 0px !important;
    max-width: 230px !important;
  }

  .sidebar .sidebarLogo {
    justify-content: flex-start;
  }


  .activeSidebar.sidebar li a .tabText {
    visibility: visible;
    opacity: 1;
  }



  .mainheader {
    padding-left: 0px;
    width: 100%;
  }

  .mainheader.activeHeader {
    padding-left: 0px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .closeSidebar {
    display: block;
  }

  table thead tr th,
  table tbody tr th,
  table tbody tr td {
    white-space: nowrap !important;
  }

  .cFilter>div {
    min-width: 95px;
  }

  .scrollableTabs {
    max-width: 100%;
    margin: 24px 16px 7px;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .scrollableTabs>.sumOptions {
    padding: 0px !important;
    flex-direction: row !important;
  }

  .scrollableTabs>.sumOptions a {
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 16px;
    margin-right: 16px;
  }

  .scrollableTabs>.sumOptions a {
    border-right: 1px solid rgba(170, 170, 170, 0.3);
    line-height: 24px;
    display: block;
    white-space: nowrap;
  }

  .scrollableTabs>.sumOptions a:last-child {
    margin-right: 0px;
    padding-right: 0px;
    border-right: 0px;
  }

  .apprvdInflu h6 {
    width: 40%;
  }

  button {
    min-width: auto !important;
  }
}



@media only screen and (max-width:767px) {
  .formSide {
    padding: 50px 0px 0px;
    min-height: auto;
  }

  .subsPlanHead {
    text-align: center;
  }

  .viewActPlan {
    width: 100%;
    text-align: center;
  }

  .viewActPlan a {
    font-size: 14px;
  }

  .packagePlans .planItem {
    width: calc(50% - 16px);
  }

  .planItem h2 {
    font-size: 26px;
  }

  .bgDiv {
    padding: 1rem;
    height: auto;
  }

  .bgDiv .lftSide {
    padding: 40px 30px;
  }

  .fromContainer {
    max-width: 340px;
  }

  .signUpscreen {
    max-width: 90% !important;
  }

  .signUpscreen>div {
    padding-right: 16px;
  }

  .cFilter>div:first-child {
    width: 100%;
  }

  .cFilter>div {
    min-width: auto;
    width: 18%;
  }

  .scrollRoles {
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .assRight {
    width: 650px;
  }

  .bodyArea {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  button {
    min-width: auto !important;
  }

  .signupbg,
  .signupbg .formSide {
    height: auto;
  }
}

@media only screen and (max-width:575px) {
  .carousel1 .carousel-caption p {
    font-size: var(--fs14);
  }

  .reg_pointer li {
    font-size: 20px;
  }

  .maintagline {
    padding-top: 24px;
  }

  .viewAmtBlcks .amtblckItem {
    width: 100%;
  }

  .packagePlans .planItem {
    width: 100%;
  }

  .planItem h2 {
    font-size: 32px;
  }

  .signUpscreen {
    max-width: 100% !important;
  }

  .mainTitle {
    gap: 16px;
  }

  .rowPerPage,
  .pageSteps {
    width: 100%;
    justify-content: center;
  }

  .cFilter>div:first-child {
    width: 100%;
  }

  .cFilter>div:first-child .react-datepicker-wrapper,
  .cFilter>div:first-child .react-datepicker-wrapper button {
    width: 100%;
  }

  .cFilter>div {
    min-width: 49%;
  }

  .sContrl {
    width: 100%;
  }

  .viewDetTabs {
    overflow-y: hidden;
    overflow-x: scroll;
    max-width: 100%;
  }

  .viewDetTabs button {
    font-size: 13px;
    line-height: normal;
  }

  .campDetailPrev h6>span.label {
    display: block;
    width: 100%;
    line-height: normal;
    margin-bottom: 4px;
  }

  .campDetailPrev h6>span.label+span {
    width: 100%;
    display: block;
    line-height: normal;
  }

  .mainTitle h1 {
    width: 100%;
  }

  .influDet {
    width: 100%;
  }

  .followItems .item {
    padding: 16px;
  }

  .followItems .item ul {
    gap: 8px;
  }

  .followItems .item ul li {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .followItems .item ul li span,
  .followItems>span {
    font-size: 16px;
  }

  .footer {
    padding: 0px 16px;
  }

  .footer p {
    font-size: 12px !important;
  }

  .progressbarList>.barListItem {
    width: 100%;
  }

  .influAppPage>div {
    width: 49%;
  }

  .influAppPage>div:nth-child(3) {
    width: calc(100% - 30px);
  }

  .btnLgGrp {
    FLEX-WRAP: wrap;
    gap: 5px;
    padding: 16px !important;
  }

  .btnLgGrp button {
    width: 49%;
    margin: 0px !important;
  }

  .btnLgGrp button {
    min-width: auto;
  }

  .apprvdInflu h6 {
    width: 100%;
    margin: 0px;
  }

  .allFilter.creatorFilter {
    justify-content: flex-start;
  }

  .creatorFilter>div {
    width: 49%;
  }

  .cmpyMgmt>div {
    flex-wrap: wrap;
  }

  .cmpyMgmt>div>div {
    width: 100%;
  }

  .allFilter.cmpyMgmt {
    width: 100%;
  }

  .cmpyMgmt>div {
    flex-wrap: wrap;
    width: 100%;
  }

  .cmpyMgmt>div>div {
    width: 90%;
  }

  .cmpyMgmt>div>div+svg {
    width: 7%
  }

  button {
    min-width: auto !important;
  }

  .communityBlock .react-multi-carousel-track {
    gap: 0px;
  }


  .active {
    border-color: #07f !important;
    border-color: var(--color-blue) !important;
  }
}



/* End Responsive Layout */