@font-face {
    font-family: 'Gilmer';
    src: url('../fonts/Gilmer-Bold.woff2') format('woff2'),
        url('../fonts/Gilmer-Heavy.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilmer';
    src: url('../fonts/Gilmer-Medium.woff2') format('woff2'),
        url('../fonts/Gilmer-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilmer';
    src: url('../fonts/Gilmer-Regular.woff2') format('woff2'),
        url('../fonts/Gilmer-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilmer';
    src: url('../fonts/Gilmer-Thin.woff2') format('woff2'),
        url('../fonts/Gilmer-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilmer';
    src: url('../fonts/Gilmer-Bold.woff2') format('woff2'),
        url('../fonts/Gilmer-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilmer';
    src: url('../fonts/Gilmer-Light.woff2') format('woff2'),
        url('../fonts/Gilmer-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

